import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { navTrans } from 'translations';

import { Container, Button, Collapse } from 'react-bootstrap';

import LocalizedLink from 'components/LocalizedLink';

import { ReactComponent as ArrowLeft } from 'assets/images/icons/ArrowLeft.svg';
import { ReactComponent as ArrowUp } from 'assets/images/icons/ArrowUp.svg';

const NavBar = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const [open, setOpen] = React.useState(false);
    const { model: modelId } = useSelector(state => state.config);
    const { modelsData, builderData, specificationsData, errorData } = useSelector(state => state.data);

    const { model } = builderData;

    const pathname = location.pathname.split('/').pop();
    const isPageGrades = pathname === 'grade';
    const showBackButton = React.useMemo(() =>
        ['grade', 'select', 'assemble', 'conclusion'].includes(pathname) && !errorData?.type,
        [pathname, errorData]
    );

    const title = !showBackButton ? modelsData[0]?.brand :
        Object.values(specificationsData)[0]?.[0].model.name ||
        model?.name ||
        t('first_step_title');

    const togglerProps = {
        className: `c-steps__toggler${open ? ' is-active' : ''}`,
        variant: 'link',
        onClick: () => setOpen(!open)
    };

    return (<>
        <header className="c-header">
            <Container>
                {showBackButton && (
                    <LocalizedLink
                        to={isPageGrades ? '/' : `/grade?id=${modelId}`}
                        className="c-header__back c-back"
                    >
                        <ArrowLeft className="c-back__ico" />
                        <span className="c-back__label">
                            {isPageGrades ? t('header_back_homepage') : t('header_back')}
                        </span>
                    </LocalizedLink>
                )}
                <h2 className="c-header__title">{title}</h2>
            </Container>
        </header>
        <nav className="c-steps">
            <Container>
                <Button {...togglerProps}>
                    <ArrowUp className="c-ico" />
                </Button>
                <Collapse in={open}>
                    <div className="c-steps__collapse">
                        <div className={`c-steps__nav${showBackButton && !isPageGrades ? ' active' : ''}`}>
                            {navTrans.links.map((link, idx) => (
                                <LocalizedLink
                                    key={`navlink-${idx}`}
                                    to={link.href}
                                    type="navlink"
                                    className="c-steps__link"
                                >
                                    <span className="c-steps__text">
                                        <small className="c-steps__count">{idx + 1}</small>
                                        <span className="c-steps__label">{t(link.label)}</span>
                                    </span>
                                </LocalizedLink>
                            ))}
                        </div>
                    </div>
                </Collapse>
            </Container>
        </nav>
    </>);
};

export default NavBar;
