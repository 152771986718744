import { useTranslation } from 'react-i18next';

const useLocale = () => {
  const { i18n } = useTranslation();

  const language = i18n.language;
  const languageTags = language.split('-');

  if (languageTags[1]) return languageTags.join('_');

  const domain = window.location.hostname.split('.').pop();
  const supportedLngs = i18n.options.supportedLngs;
  const fallbackLng = i18n.options.fallbackLng?.[0] || 'en';
  const locale = supportedLngs.includes(domain) ? domain : 'ee';

  return [language, locale.toUpperCase()].join('_');
};

export default useLocale;